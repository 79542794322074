import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { useAppDependenciesContext } from '../../context/AppDependencies';

export function Image({ dealerId }) {
  const [binary, setBinary] = useState('');
  const { appData, config, homeDir } = useAppDependenciesContext();

  const getFilename = useCallback(() => {
    if (!appData || !appData.qrImages) return;

    const file = appData.qrImages.find((item) => item.filename.includes(dealerId));

    return file;
  }, [appData, dealerId]);

  const parseFileName = useCallback(
    (name) => {
      if (!name) return `${dealerId}.jpg`;

      return name.filename;
    },
    [dealerId],
  );

  const getImage = useCallback(async () => {
    const filename = parseFileName(getFilename());

    try {
      const { data, headers } = await axios.get(`file://${homeDir}/qr/${filename}`, {
        responseType: 'arraybuffer',
      });

      const blob = new Blob([data], { type: headers['content-type'] });
      setBinary(URL.createObjectURL(blob));
    } catch {
      const response = await axios.get(`${config?.apiUrl}/api/image/production/${filename}`, {
        responseType: 'arraybuffer',
      });
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      setBinary(URL.createObjectURL(blob));
    }
  }, [config?.apiUrl, getFilename, homeDir, parseFileName]);

  useEffect(() => {
    getImage();
  }, [getImage]);

  return <img className="image" src={binary} alt="" />;
}

Image.propTypes = {
  dealerId: PropTypes.string,
};
