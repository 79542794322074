import { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useAppDependenciesContext } from '../../../context/AppDependencies';
import { useRegionContext } from '../../../context/Region';

import { NavItem } from './NavItem';

export function NavItems({ items = [], depth = 0 }) {
  const { appData } = useAppDependenciesContext();
  const { currentRegion } = useRegionContext();

  const isActive = useCallback(
    (region) => {
      if (!currentRegion) return false;

      return currentRegion.code === region;
    },
    [currentRegion],
  );

  return items
    .map((item) => ({
      ...item,
      count: appData.dealersOnMap.find((i) => i.map_region === item.code)?.dealers || 0,
    }))
    .filter(({ count }) => count > 0)
    .map(({ path, title, children, code, count }) => {
      const key = `${title}-${depth}`;

      if (children) {
        return (
          <NavItem active={isActive(code)} key={key} title={title} depth={depth} path={path} count={count} code={code}>
            <NavItems items={children} depth={depth + 1} />
          </NavItem>
        );
      }

      return (
        <NavItem active={isActive(code)} key={key} title={title} depth={depth} path={path} count={count} code={code} />
      );
    });
}

NavItems.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      code: PropTypes.string,
    }),
  ),
  depth: PropTypes.number,
};
