import { useCallback, useEffect, useState } from 'react';
import isElectron from 'is-electron';
import axios from 'axios';
import { useToken } from './useToken';

export default function useAppDependencies() {
  const { token } = useToken();

  const [appDeps, setAppDeps] = useState({
    appData: null,
    homeDir: null,
    isOffline: false,
    config: null,
  });

  const getConfig = async () => {
    const url = isElectron() ? './config.json' : '/config.json';
    const { data } = await axios.get(url);

    return data;
  };

  const getAppData = async ({ config, homeDirectory }) => {
    try {
      const { data: localData } = await axios.get(`file://${homeDirectory}/application.json`);

      return { data: localData };
    } catch {
      return axios.get(`${config.apiUrl}/api/production/application`);
    }
  };

  const getElectronData = useCallback(async () => {
    const config = await getConfig();
    const homeDirectory = await window.api.getHomeDirectory();

    const { data: appData } = await getAppData({ config, homeDirectory });

    let isOffline = false;
    setAppDeps((prevAppDeps) => ({
      ...prevAppDeps,
      homeDir: homeDirectory,
      config,
      isOffline: isOffline,
      appData,
    }));

    window.api.download({ token }).then(async () => {
      isOffline = true;
      const { data: appData } = await getAppData({ config, homeDirectory });

      setAppDeps((prevAppDeps) => ({
        ...prevAppDeps,
        homeDir: homeDirectory,
        config,
        isOffline: isOffline,
        appData,
      }));
    });
  }, [token]);

  const getBrowserData = useCallback(async () => {
    const config = await getConfig();

    const { data: appData } = await axios.get(`${config.apiUrl}/api/production/application`);

    setAppDeps((prevAppDeps) => ({
      ...prevAppDeps,
      config: config,
      appData: appData,
    }));
  }, []);

  const syncApplicationData = useCallback(async () => {
    if (isElectron()) {
      await getElectronData();
    } else {
      await getBrowserData();
    }
  }, [getBrowserData, getElectronData]);

  useEffect(() => {
    syncApplicationData();
  }, [syncApplicationData]);

  return { appDeps, syncApplicationData };
}
