import { useMemo } from 'react';
import { useAppDependenciesContext } from '../context';
import { getRegionsCode } from '../utils';

export function useDisabledStates() {
  const { appData } = useAppDependenciesContext();

  const disabledStates = useMemo(() => {
    if (!appData) return [];

    const { dealersOnMap } = appData;

    return getRegionsCode().filter(
      (current) => dealersOnMap.filter((other) => current === other.map_region).length === 0,
    );
  }, [appData]);

  return { disabledStates };
}
