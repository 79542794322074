export const MAP_ZOOM_TO_ELEMENT_DURATION = 1000; // After this time map will move left and zoom and dealers controls will animate
export const MAP_MOVE_LEFT_DURATION = 300; // Time map will be moving left, needs to be same for zoom and dealers controls
export const MAP_MOVE_LEFT_ON_ZOOM_TO_ELEMENT = -670; // How much move map to left on zoom to element
export const MAP_ZOOM_TO_ELEMENT_SCALE = {
  REGULAR: 0.8,
  SMALL: 1.3,
};

export const MAP_STYLE = {
  zoomIn: {
    marginLeft: MAP_MOVE_LEFT_ON_ZOOM_TO_ELEMENT,
    transitionProperty: 'margin-left',
    transitionDuration: `${MAP_MOVE_LEFT_DURATION / 1000}s`,
    transitionDelay: `${MAP_ZOOM_TO_ELEMENT_DURATION / 1000}s`,
  },
  zoomOut: {
    marginLeft: 0,
    transitionProperty: 'margin-left',
    transitionDuration: `${MAP_MOVE_LEFT_DURATION / 1000}s`,
    transitionDelay: `${MAP_ZOOM_TO_ELEMENT_DURATION / 1000}s`,
  },
};

export const INITIAL_MAP_POSITION = {
  scale: 0.6025,
  positionX: 0,
  positionY: -675,
};

export const SMALL_STATES = ['NH', 'DE', 'MD', 'NJ', 'RI', 'NS', 'PE', 'MA', 'VT', 'CT'];
