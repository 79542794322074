import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useIdleTimer } from 'react-idle-timer';
import { MapContext } from '../../context/Map';
import {
  INITIAL_MAP_POSITION,
  MAP_STYLE,
  MAP_ZOOM_TO_ELEMENT_DURATION,
  MAP_ZOOM_TO_ELEMENT_SCALE,
  SMALL_STATES,
} from '../../constants/map';
import { useRegionContext } from '../../context/Region';
import { IDLE_TIME } from '../../constants/time';
import { useAppDependenciesContext } from '../../context/AppDependencies';
import isElectron from 'is-electron';
import { Loader } from '../../components/Loader';

export function MapTransformProvider({ renderControls, renderHeader, renderMap }) {
  const { appData, config, homeDir } = useAppDependenciesContext();
  const { currentRegion, setCurrentRegion } = useRegionContext();
  const { syncApplicationData } = useAppDependenciesContext();

  const [isLeftPanelVisible, setIsLeftPanelVisible] = useState(false);
  const [isRightPanelVisible, setIsRightPanelVisible] = useState(false);
  const [shouldReduceViewport, setShouldReduceViewport] = useState(false);
  const [mapStyle, setMapStyle] = useState(MAP_STYLE['zoomOut']);
  const transformWrapperRef = useRef();

  const zoomToRegion = (regionId) => {
    if (!transformWrapperRef.current) {
      return;
    }

    const region = regionId.split('region-').join('');
    const scale = SMALL_STATES.includes(region) ? MAP_ZOOM_TO_ELEMENT_SCALE.SMALL : MAP_ZOOM_TO_ELEMENT_SCALE.REGULAR;

    const { zoomToElement } = transformWrapperRef.current;

    if (isRightPanelVisible && currentRegion && currentRegion.code !== regionId) {
      setTimeout(() => {
        zoomToElement(regionId, scale, MAP_ZOOM_TO_ELEMENT_DURATION);
        return;
      })
    }

    setTimeout(() => {
      zoomToElement(regionId, scale, MAP_ZOOM_TO_ELEMENT_DURATION);
      setMapStyle(MAP_STYLE['zoomIn']);
    })

  };

  const handleRestart = async () => {
    if (!transformWrapperRef.current) {
      return;
    }
    const { resetTransform } = transformWrapperRef.current;

    resetTransform(MAP_ZOOM_TO_ELEMENT_DURATION);
    setMapStyle(MAP_STYLE['zoomOut']);
    setIsRightPanelVisible(false);
    setShouldReduceViewport(false);

    await syncApplicationData();

    setTimeout(() => {
      setCurrentRegion(null);
    }, 1400);
  };

  const shouldRenderLoader = () => {
    return (!appData || !config) && isElectron() && !homeDir;
  };

  useIdleTimer({
    onIdle: async () => {
      handleRestart();
      await syncApplicationData();
    },
    timeout: IDLE_TIME,
    throttle: 500,
  });

  return (
    <MapContext.Provider
      value={{
        isLeftPanelVisible,
        isRightPanelVisible,
        mapStyle,
        handleRestart,
        setIsLeftPanelVisible,
        setIsRightPanelVisible,
        setMapStyle,
        setShouldReduceViewport,
        zoomToRegion,
      }}
    >
      {shouldRenderLoader() ? (
        <Loader />
      ) : (
        <TransformWrapper
          minScale={0.3}
          maxScale={1.5}
          initialScale={INITIAL_MAP_POSITION.scale}
          initialPositionX={INITIAL_MAP_POSITION.positionX}
          initialPositionY={INITIAL_MAP_POSITION.positionY}
          limitToBounds={!currentRegion}
          disablePadding={!currentRegion}
          panning={
            {
              disabled: currentRegion
            }
          }
          ref={transformWrapperRef}
        >
          {renderHeader()}

          <TransformComponent
            wrapperStyle={{
              maxWidth: shouldReduceViewport ? 'calc(100vw - 1072px)' : '100vw',
              maxHeight: 'calc(100vh)',
            }}
          >
            {renderMap()}
          </TransformComponent>

          {renderControls()}
        </TransformWrapper>
      )}
    </MapContext.Provider>
  );
}

MapTransformProvider.propTypes = {
  renderControls: PropTypes.func.isRequired,
  renderHeader: PropTypes.func.isRequired,
  renderMap: PropTypes.func.isRequired,
};
