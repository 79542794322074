import { useMemo, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useTransformContext } from 'react-zoom-pan-pinch';
import { DealerPanel } from '../../components/DealerPanel';
import { ZoomControls } from '../../components/ZoomControls';
import { INITIAL_MAP_POSITION, MAP_MOVE_LEFT_DURATION, MAP_ZOOM_TO_ELEMENT_DURATION } from '../../constants/map';
import { useMapContext } from '../../context/Map';
import { useRegionContext } from '../../context/Region';

const zoomControlsStyles = {
  entering: { right: 1072 },
  entered: { right: 1072 },
  exiting: { right: 0 },
  exited: { right: 0 },
};

const dealerPanelStyles = {
  entering: { right: 0 },
  entered: { right: 0 },
  exiting: { right: -1240 },
  exited: { right: -1240 },
};

const ZOOM_CONTROLS_TRANSITION = {
  transition: `all ${MAP_MOVE_LEFT_DURATION / 1000}s ease-in-out ${MAP_ZOOM_TO_ELEMENT_DURATION / 1000}s`,
};

const DEALER_CONTROLS_TRANSITION = {
  transition: `all ${MAP_MOVE_LEFT_DURATION / 1000}s ease-in-out ${MAP_ZOOM_TO_ELEMENT_DURATION / 1000}s`,
};

export function MapControls() {
  const ref = useRef();

  const { currentRegion } = useRegionContext();
  const { zoomIn, zoomOut, setTransform, state } = useTransformContext();
  const { isRightPanelVisible, handleRestart, setShouldReduceViewport } = useMapContext();

  const isCenterButtonVisible = useMemo(() => {
    return (
      !currentRegion &&
      (state.positionX !== INITIAL_MAP_POSITION.positionX ||
        state.positionY !== INITIAL_MAP_POSITION.positionY ||
        state.scale !== INITIAL_MAP_POSITION.scale)
    );
  }, [currentRegion, state.positionX, state.positionY, state.scale]);

  const handleCenter = () => {
    setTransform(INITIAL_MAP_POSITION.positionX, INITIAL_MAP_POSITION.positionY, INITIAL_MAP_POSITION.scale);
  };

  return (
    <CSSTransition
      nodeRef={ref}
      in={isRightPanelVisible}
      onEntered={() => setTimeout(() => setShouldReduceViewport(true), 1400)}
      timeout={300}
    >
      {(state) => (
        <div ref={ref}>
          <ZoomControls
            onDecrease={() => zoomOut(0.5, 1000)}
            onIncrease={() => zoomIn(0.5, 1000)}
            onCenter={handleCenter}
            isCenterButtonVisible={isCenterButtonVisible}
            style={{ ...zoomControlsStyles[state], ...ZOOM_CONTROLS_TRANSITION }}
          />

          <DealerPanel onReset={handleRestart} style={{ ...dealerPanelStyles[state], ...DEALER_CONTROLS_TRANSITION }} />
        </div>
      )}
    </CSSTransition>
  );
}
