export const NAV_ITEMS = [
  {
    title: 'United States',
    countryCode: 'US',
    items: [
      { title: 'Alaska', code: 'AK' },
      { title: 'Alabama', code: 'AL' },
      { title: 'Arkansas', code: 'AR' },
      { title: 'Arizona', code: 'AZ' },
      { title: 'California', code: 'CA' },
      { title: 'Colorado', code: 'CO' },
      { title: 'Connecticut', code: 'CT' },
      { title: 'Delaware', code: 'DE' },
      { title: 'District of Columbia', code: 'DC' },
      { title: 'Florida', code: 'FL' },
      { title: 'Georgia', code: 'GA' },
      { title: 'Hawaii', code: 'HI' },
      { title: 'Iowa', code: 'IA' },
      { title: 'Idaho', code: 'ID' },
      { title: 'Illinois', code: 'IL' },
      { title: 'Indiana', code: 'IN' },
      { title: 'Kansas', code: 'KS' },
      { title: 'Kentucky', code: 'KY' },
      { title: 'Louisiana', code: 'LA' },
      { title: 'Massachusetts', code: 'MA' },
      { title: 'Maryland', code: 'MD' },
      { title: 'Maine', code: 'ME' },
      { title: 'Michigan', code: 'MI' },
      { title: 'Minnesota', code: 'MN' },
      { title: 'Missouri', code: 'MO' },
      { title: 'Mississippi', code: 'MS' },
      { title: 'Montana', code: 'MT' },
      { title: 'North Carolina', code: 'NC' },
      { title: 'North Dakota', code: 'ND' },
      { title: 'Nebraska', code: 'NE' },
      { title: 'New Hampshire', code: 'NH' },
      { title: 'New Jersey', code: 'NJ' },
      { title: 'New Mexico', code: 'NM' },
      { title: 'Nevada', code: 'NV' },
      { title: 'New York', code: 'NY' },
      { title: 'Ohio', code: 'OH' },
      { title: 'Oklahoma', code: 'OK' },
      { title: 'Oregon', code: 'OR' },
      { title: 'Pennsylvania', code: 'PA' },
      { title: 'Rhode Island', code: 'RI' },
      { title: 'South Carolina', code: 'SC' },
      { title: 'South Dakota', code: 'SD' },
      { title: 'Tennessee', code: 'TN' },
      {
        title: 'Texas',
        code: 'TX',

        children: [
          { title: 'Texas - NE', code: 'TX_TXNE' },
          { title: 'Texas - NW', code: 'TX_TXNW' },
          { title: 'Texas - SE', code: 'TX_TXSE' },
        ],
      },
      { title: 'Utah', code: 'UT' },
      { title: 'Virginia', code: 'VA' },
      { title: 'Vermont', code: 'VT' },
      { title: 'Washington', code: 'WA' },
      { title: 'Wisconsin', code: 'WI' },
      { title: 'West Virginia', code: 'WV' },
      { title: 'Wyoming', code: 'WY' },
    ],
  },
  {
    title: 'Canada',
    countryCode: 'CA',
    items: [
      { title: 'Alberta', code: 'AB' },
      { title: 'British Columbia', code: 'BC' },
      { title: 'Manitoba', code: 'MB' },
      { title: 'New Brunswick', code: 'NB' },
      { title: 'Newfoundland and Labrador', code: 'NL' },
      { title: 'Northwest Territories', code: 'NT' },
      { title: 'Nova Scotia', code: 'NS' },
      { title: 'Nunavut', code: 'NU' },
      { title: 'Ontario', code: 'ON' },
      { title: 'Prince Edward Island', code: 'PE' },
      { title: 'Quebec', code: 'QC' },
      { title: 'Saskatchewan', code: 'SK' },
      { title: 'Yukon Territory', code: 'YT' },
    ],
  },
  { title: 'Puerto Rico', countryCode: 'PR', items: [{ title: 'Puerto Rico', code: 'PR' }] },
];
