import { createContext, useContext } from 'react';

export const MapContext = createContext(undefined);

export function useMapContext() {
  const context = useContext(MapContext);

  if (context === undefined) {
    throw new Error('Cannot use "useMapContext" outside the "MapTransformProvider"');
  }

  return context;
}
