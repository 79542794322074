import React, { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import isElectron from 'is-electron';
import { CSSTransition } from 'react-transition-group';
import { ReactComponent as KeyboardIcon } from '../../assets/icons/keyboard.svg';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

import './index.css';

const keyboardStyles = {
  entering: { bottom: 0 },
  entered: { bottom: 0 },
  exiting: { bottom: -350 },
  exited: { bottom: -350 },
};

export default function Login({ setToken }) {
  const [password, setPassword] = useState('');
  const [errorClass, setErrorClass] = useState('');
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  const [layout, setLayout] = useState('default');

  const loginUser = async (credentials) => {
    try {
      const url = isElectron() ? './config.json' : '/config.json';
      const { data } = await axios.get(url);

      const response = await axios.post(`${data.apiUrl}/api/login_check`, {
        username: 'api',
        password: credentials.password,
      });

      return response.data.token;
    } catch (err) {
      setErrorClass('is-invalid');
    }
  };

  const handleSubmit = async (e) => {
    setErrorClass('');
    e.preventDefault();

    const token = await loginUser({ password });

    if (token) {
      setToken(token);
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const toggleKeyboard = () => setIsKeyboardVisible((prevIsKeyboardVisible) => !prevIsKeyboardVisible);

  const onKeyPress = (button) => {
    if (button === '{shift}' || button === '{lock}') {
      setLayout((prevLayout) => (prevLayout === 'default' ? 'shift' : 'default'));
    }
  };

  return (
    <>
      <div className="site-wrapper">
        <div className="site-wrapper-inner">
          <div className="cover-container">
            <div className="masthead clearfix">
              <div className="inner">
                <h3 className="masthead-brand">CASE Dealer Map</h3>
              </div>
            </div>
            <div className="accessCode inner cover">
              <h1 className="cover-heading">Access Code</h1>
              <div className="lead">
                <form onSubmit={handleSubmit}>
                  <div className="mx-5 my-3">
                    <input
                      value={password}
                      type="password"
                      onChange={handlePasswordChange}
                      className={`form-control rounded-0 ${errorClass}`}
                      aria-describedby="accessCode"
                    />
                    {errorClass && <div className="invalid-feedback">Invalid Access Code</div>}
                  </div>
                  <div className="mx-5 my-3">
                    <button type="submit" className="btn btn-secondary float-start rounded-0">
                      Go
                    </button>
                    <button type="button" onClick={toggleKeyboard} className="btn btn-secondary float-end rounded-0">
                      <KeyboardIcon className="keyboard-icon" />
                    </button>
                    <div className="clearfix"></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CSSTransition timeout={300} in={isKeyboardVisible}>
        {(state) => (
          <div className="keyboard-wrapper" style={{ ...keyboardStyles[state] }}>
            <Keyboard
              layoutName={layout}
              theme="hg-theme-default myTheme1"
              onChange={(input) => setPassword(input)}
              onKeyPress={onKeyPress}
            />
          </div>
        )}
      </CSSTransition>
    </>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
