import {useRef} from 'react';
import {CSSTransition} from 'react-transition-group';

import {ReactComponent as Logo} from '../../assets/icons/logo.svg';
import {useMapContext} from '../../context/Map';

import {DealerDropdown} from '../DealerDropdown';
import {LocationsSidebar} from '../LocationsSidebar';

import styles from './index.module.scss';

const locationsSidebarStyles = {
    entering: {left: 0},
    entered: {left: 0},
    exiting: {left: -480},
    exited: {left: -480},
};

export function Header() {

    const ref = useRef();
    const {isLeftPanelVisible, setIsLeftPanelVisible, handleRestart} = useMapContext();

    return (
        <>
            <div className={styles.header}>
                <button type="button" className={styles['header-logo']} onClick={handleRestart} to="/">
                    <Logo className={styles.logo}/>
                </button>

                <DealerDropdown
                    isOpen={isLeftPanelVisible}
                    toggle={() => setIsLeftPanelVisible((prevIsLeftPanelVisible) => !prevIsLeftPanelVisible)}
                />
            </div>

            <CSSTransition nodeRef={ref} in={isLeftPanelVisible} timeout={300}>
                {(state) => (
                    <LocationsSidebar ref={ref} isOpen={isLeftPanelVisible} style={{...locationsSidebarStyles[state]}}/>
                )}
            </CSSTransition>
        </>
    );
}
