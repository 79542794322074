import { CODES } from '../constants/equipment';
import { NAV_ITEMS } from '../constants/nav';

export function getRegions() {
  return NAV_ITEMS.map(({ items }) => items)
    .flat()
    .map((item) => item?.children || item)
    .flat();
}

export function getRegion(region) {
  return getRegions().find(({ code }) => code === region);
}

export function getDealersForRegion({ dealers = [], region }) {

  let dealersRes =  dealers.filter((dealer) => {

      let dealerPlacements = dealer.dealerMapPlacements.filter((mapPlacement) => {

      if (mapPlacement.subregion) {
        return region === mapPlacement.state.abbr || region === `${mapPlacement.state.abbr}_${mapPlacement.subregion.abbr}`
      }

      return region === mapPlacement.state.abbr;

    });

    return dealerPlacements.length > 0;

  });

  return dealersRes;
}

export function removeIgnoredCodes(code) {
  return CODES.includes(code);
}

export function getRegionsCode() {
  return getRegions().map(({ code }) => code);
}
