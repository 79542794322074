import { ReactComponent as BackhoeLoadersIcon } from '../../assets/icons/equipment/equipment-01-backhoe-loaders.svg';
import { ReactComponent as CompactDozerLoadersIcon } from '../../assets/icons/equipment/equipment-02-compact-dozer-loaders.svg';
import { ReactComponent as CompactTrackLoadersIcon } from '../../assets/icons/equipment/equipment-03-compact-track-loaders.svg';
import { ReactComponent as CompactionEquipmentIcon } from '../../assets/icons/equipment/equipment-04-compaction-equipment.svg';
import { ReactComponent as CrawlerDozersIcon } from '../../assets/icons/equipment/equipment-05-crawler-dozers.svg';
import { ReactComponent as CompactExcavatorsMiniIcon } from '../../assets/icons/equipment/equipment-06-compact-excavators_mini.svg';
import { ReactComponent as ExcavatorsIcon } from '../../assets/icons/equipment/equipment-07-excavators.svg';
import { ReactComponent as ForkliftsIcon } from '../../assets/icons/equipment/equipment-08-forklifts.svg';
import { ReactComponent as MotorGradersIcon } from '../../assets/icons/equipment/equipment-09-motor-graders.svg';
import { ReactComponent as SkidSteerLoadersIcon } from '../../assets/icons/equipment/equipment-10-skid-steer-loaders.svg';
import { ReactComponent as TractorLoadersIcon } from '../../assets/icons/equipment/equipment-11-tractor-loaders.svg';
import { ReactComponent as CompactWheelLoadersIcon } from '../../assets/icons/equipment/equipment-12-compact-wheel-loaders.svg';
import { ReactComponent as WheelLoadersIcon } from '../../assets/icons/equipment/equipment-13-wheel-loaders.svg';
import { ReactComponent as PartsIcon } from '../../assets/icons/equipment/service-14-parts.svg';
import { ReactComponent as PrecisionConstructionIcon } from '../../assets/icons/equipment/service-15-precision-construction.svg';

export const ICONS = [
  { code: 'UT', order: 1, Icon: BackhoeLoadersIcon },
  { code: 'UT', order: 11, Icon: TractorLoadersIcon },
  { code: 'UT', order: 8, Icon: ForkliftsIcon },
  { code: 'SL', order: 10, Icon: SkidSteerLoadersIcon },
  { code: 'SL', order: 3, Icon: CompactTrackLoadersIcon },
  { code: 'CE', order: 5, Icon: CrawlerDozersIcon },
  { code: 'CE', order: 13, Icon: WheelLoadersIcon },
  { code: 'MX', order: 6, Icon: CompactExcavatorsMiniIcon },
  { code: 'MX', order: 12, Icon: CompactWheelLoadersIcon },
  { code: 'CM', order: 4, Icon: CompactionEquipmentIcon },
  { code: 'CX', order: 7, Icon: ExcavatorsIcon },
  { code: 'GR', order: 9, Icon: MotorGradersIcon },
  { code: 'MW', order: 12, Icon: CompactWheelLoadersIcon },
  { code: 'L2', order: 15, Icon: PrecisionConstructionIcon },
  { code: 'L3', order: 15, Icon: PrecisionConstructionIcon },
  { code: 'LC', order: 15, Icon: PrecisionConstructionIcon },
  { code: 'CP', order: 14, Icon: PartsIcon },
  { code: 'MD', order: 2, Icon: CompactDozerLoadersIcon },
];

export const CODES = [...new Set(ICONS.map((item) => item.code))];
