import { useMemo, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';

import { ReactComponent as ChevronDownIcon } from '../../assets/icons/chevron-down.svg';
import { ReactComponent as ChevronRightIcon } from '../../assets/icons/chevron-right.svg';

import styles from './index.module.scss';

export function Dropdown({ label, children, count, style, isNested, isOpen }) {
  const [height, setHeight] = useState(isOpen ? 'auto' : 0);

  const Icon = useMemo(() => (height === 'auto' ? ChevronDownIcon : ChevronRightIcon), [height]);

  const toggle = () => {
    setHeight((prevHeight) => {
      const nextHeight = prevHeight === 0 ? 'auto' : 0;

      return nextHeight;
    });
  };

  return (
    <>
      <button
        type="button"
        onClick={toggle}
        style={style}
        className={classnames(styles['dropdown-header'], { [styles.nested]: isNested })}
      >
        <div className={styles['dropdown-header-wrapper']}>
          <Icon className={styles['dropdown-header-icon']} />
          <span className={styles['dropdown-header-label']}>{label}</span>
        </div>

        {Number.isInteger(count) && <span className={styles['dropdown-header-counter']}>{count}</span>}
      </button>

      <AnimateHeight duration={300} height={height} easing="ease-in-out">
        {children}
      </AnimateHeight>
    </>
  );
}

Dropdown.propTypes = {
  label: PropTypes.string,
  count: PropTypes.number,
  style: PropTypes.object,
  isNested: PropTypes.bool,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
};
