import { createContext, useContext, useState } from 'react';

export const RegionContext = createContext(undefined);

export function RegionContextProvider({ children }) {
  const [currentRegion, setCurrentRegion] = useState(null);

  return <RegionContext.Provider value={{ currentRegion, setCurrentRegion }}>{children}</RegionContext.Provider>;
}

export function useRegionContext() {
  const context = useContext(RegionContext);

  if (context === undefined) {
    throw new Error('Cannot use "useRegionContext" outside the "RegionContextProvider"');
  }

  return context;
}
