import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

import { ReactComponent as PhoneIcon } from '../../assets/icons/phone.svg';
import { removeIgnoredCodes } from '../../utils';

import styles from './index.module.scss';
import { ICONS } from '../../constants/equipment';
import { Image } from '../Image';

const duration = 500;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const dealerCardTransition = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export function DealerCard({ dealer, dealers = [], index }) {
  const nodeRef = useRef();
  const contracts = useMemo(() => dealer.contracts.split(', ').filter(removeIgnoredCodes), [dealer]);
  const [isVisible, setIsVisible] = useState(false);

  const renderIcons = useCallback(() => {
    const iconsToRender = contracts
      .map((code) => {
        const icons = ICONS.filter((icon) => icon.code === code);

        return icons;
      })
      .flat()
      .sort((a, b) => a.order - b.order)
      .filter((value, index, self) => index === self.findIndex((t) => isEqual(t.Icon, value.Icon)));

    return iconsToRender.map(({ code, order, Icon }) => <Icon key={`${code}-${order}`} />);
  }, [contracts]);

  const shouldAddEmptyLine = useMemo(() => {
    const isEven = index % 2 === 0;
    const nextItem = dealers[index + 1];

    const prevItem = dealers[index - 1];

    return (isEven && !!nextItem?.addressLine2?.length) || (!isEven && !!prevItem?.addressLine2?.length);
  }, [dealers, index]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Transition nodeRef={nodeRef} in={isVisible} timeout={duration}>
      {(state) => (
        <div ref={nodeRef} style={{ ...defaultStyle, ...dealerCardTransition[state] }}>
          <div className={styles.card}>
            <div className={styles['card-header']}>
              <div className={styles['card-header-info']}>
                <h3>{dealer.dealerName}</h3>

                <div className={styles['card-header-info-address']}>
                  <span>{dealer.addressLine1}</span>

                  {dealer.addressLine2 && <span>{dealer.addressLine2}</span>}

                  <span>
                    {dealer.city}, {dealer.state} {dealer.postal}
                  </span>
                  {shouldAddEmptyLine && <span />}
                </div>

                <span className={styles['card-header-info-phone']}>
                  <PhoneIcon />
                  {dealer.phone}
                </span>
              </div>

              <div className={styles['card-header-qr']}>
                <Image dealerId={dealer.dealerId} />
              </div>
            </div>

            <div className={styles['card-footer']}>
              <p className={styles['card-footer-title']}>EQUIPMENT / SERVICES</p>
              <div className={styles['card-footer-equipment']}>{renderIcons()}</div>
            </div>
          </div>
        </div>
      )}
    </Transition>
  );
}

const dealerType = PropTypes.shape({
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  brand: PropTypes.string,
  city: PropTypes.string,
  contracts: PropTypes.string,
  country: PropTypes.string,
  dealerId: PropTypes.string,
  dealerName: PropTypes.string,
  lat: PropTypes.string,
  lng: PropTypes.string,
  phone: PropTypes.string,
  postal: PropTypes.string,
  subregion: PropTypes.string,
  state: PropTypes.string,
});

DealerCard.propTypes = {
  dealer: dealerType,
  dealers: PropTypes.arrayOf(dealerType),
  index: PropTypes.number,
};
