import classnames from 'classnames';

import styles from './index.module.scss';

export function Loader({ fullPage = true }) {
  return (
    <div className={classnames(styles['loader-wrapper'], { [styles['full-page']]: fullPage })}>
      <div className={styles['loader']}>
        <span />
        <span />
        <span />
      </div>
    </div>
  );
}
