import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useRegionContext } from '../../context/Region';
import { useAppDependenciesContext } from '../../context';
import { RestartButton } from '../RestartButton';
import { DealerCard } from '../DealerCard';
import { getDealersForRegion, getRegion } from '../../utils';

import styles from './index.module.scss';
import { Loader } from '../Loader';

export function DealerPanel({ style, onReset }) {
  const { currentRegion } = useRegionContext();
  const { appData } = useAppDependenciesContext();

  const { name, count } = useMemo(() => {
    if (!currentRegion) {
      return { name: '', count: 0 };
    }

    const { code: region } = currentRegion;

    if (!region) return { name: '', count: 0 };

    const { title } = getRegion(region);

    const count = appData?.dealersOnMap.find((item) => item.map_region === region)?.dealers || 0;

    return { name: title, count };
  }, [appData, currentRegion]);

  const dealers = useMemo(() => {
    if (!currentRegion) return [];

    if (!appData?.dealers) return [];

    return getDealersForRegion({ dealers: appData.dealers, region: currentRegion.code });
  }, [appData, currentRegion]);

  return (
    <div style={style} className={styles.container}>
      <div className={styles['container-header']}>
        <RestartButton onReset={onReset} />

        <div className={styles['state']}>
          <span className={styles['state-label']}>{name}</span>
          <span className={styles['state-counter']}>{count}</span>
        </div>
      </div>

      {!dealers.length ? (
        <Loader fullPage={false} />
      ) : (
        <div className={styles['container-grid']}>
          {dealers.map((dealer, index) => (
            <DealerCard key={dealer.dealerId} dealers={dealers} index={index} dealer={dealer} />
          ))}
        </div>
      )}
    </div>
  );
}

DealerPanel.propTypes = {
  style: PropTypes.object,
  onReset: PropTypes.func.isRequired,
};
