import { createContext, useContext } from 'react';
import useAppDependencies from '../../hooks/useAppDependencies';

export const AppDependenciesContext = createContext(undefined);

export function AppDependenciesContextProvider({ children }) {
  const { appDeps, syncApplicationData } = useAppDependencies();

  return (
    <AppDependenciesContext.Provider value={{ ...appDeps, syncApplicationData }}>
      {children}
    </AppDependenciesContext.Provider>
  );
}

export function useAppDependenciesContext() {
  const context = useContext(AppDependenciesContext);

  if (context === undefined) {
    throw new Error('Cannot use "useAppDependenciesContext" outside the "AppDependenciesContextProvider"');
  }

  return context;
}
