import { useMemo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Dropdown } from '../../../Dropdown';
import { useMapContext } from '../../../../context/Map';
import { useRegionContext } from '../../../../context/Region';
import { useDisabledStates } from '../../../../hooks/useDisabledStates';

import styles from './index.module.scss';

export function NavItem({ active, title, path, depth, children, code, count = 0 }) {
  const { setCurrentRegion } = useRegionContext();
  const { disabledStates } = useDisabledStates();

  const { setIsLeftPanelVisible, zoomToRegion } = useMapContext();

  const handlePickRegion = () => {
    setIsLeftPanelVisible(false);
    zoomToRegion(`region-${code}`);

    setCurrentRegion({ name: title, code, count });
  };

  const paddingLeft = useMemo(() => {
    let left = 32;

    if (depth > 0) {
      left = 32 + 16 * depth;
    }

    return left;
  }, [depth]);

  if (children) {
    return (
      <Dropdown label={title} count={count} style={{ paddingLeft }} isNested>
        {children}
      </Dropdown>
    );
  }

  return (
    <button
      type="button"
      style={{ paddingLeft }}
      onClick={handlePickRegion}
      className={classnames(styles['nav-item'], { [styles['nav-item-active']]: active })}
      disabled={disabledStates.includes(code)}
    >
      <span className={styles['nav-item-label']}>{title}</span>
      <span className={styles['nav-item-counter']}>{count}</span>
    </button>
  );
}

NavItem.propTypes = {
  active: PropTypes.bool,
  title: PropTypes.string,
  path: PropTypes.string,
  depth: PropTypes.number,
  code: PropTypes.string,
  count: PropTypes.number,
};
