import React, { useEffect } from 'react';
import { useTransformContext } from 'react-zoom-pan-pinch';

import { useRegionContext } from '../../context/Region';
import { useMapContext } from '../../context/Map';
import { useDisabledStates } from '../../hooks/useDisabledStates';
import { MapIcon } from '../../components/MapIcon';

import { MapControls } from './MapControls';

export default function Map() {
  const { currentRegion, setCurrentRegion } = useRegionContext();

  const {
    state: { scale },
  } = useTransformContext();

  const { mapStyle, setIsLeftPanelVisible, setIsRightPanelVisible, zoomToRegion } = useMapContext();

  const { disabledStates } = useDisabledStates();

  const handleRegionClick = ({ name, code }) => {
    if (disabledStates.includes(code)) {
      return;
    }

    setIsLeftPanelVisible(false);
    zoomToRegion(`region-${code}`);
    setCurrentRegion({ name, code });
  };

  useEffect(() => {
    // Opens right panel when current region is set
    if (currentRegion) {
      setIsRightPanelVisible(true);
    }
  }, [currentRegion, setIsRightPanelVisible]);

  return (
    <MapIcon
      onClick={handleRegionClick}
      zoom={scale}
      selectedState={currentRegion?.code}
      style={mapStyle}
      disabledStates={disabledStates}
    />
  );
}

Map.Controls = MapControls;
