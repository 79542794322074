import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from '../../assets/icons/minus.svg';
import { ReactComponent as CenterIcon } from '../../assets/icons/center.svg';

import styles from './index.module.scss';

export const ZoomControls = forwardRef(({ isCenterButtonVisible, onIncrease, onDecrease, onCenter, style }, ref) => {
  return (
    <div ref={ref} style={style} className={styles['controls-sidebar-wrapper']}>
      <div className={styles['controls-sidebar']}>
        {isCenterButtonVisible && (
          <div className={styles['controls-sidebar-group']}>
            <button type="button" className={styles['controls-sidebar-button']} onClick={onCenter}>
              <CenterIcon />
            </button>
          </div>
        )}
        <div className={styles['controls-sidebar-group']}>
          <button type="button" className={styles['controls-sidebar-button']} onClick={onIncrease}>
            <PlusIcon />
          </button>
          <button type="button" className={styles['controls-sidebar-button']} onClick={onDecrease}>
            <MinusIcon />
          </button>
        </div>
      </div>
    </div>
  );
});

ZoomControls.propTypes = {
  isCenterButtonVisible: PropTypes.bool,
  onCenter: PropTypes.func.isRequired,
  onIncrease: PropTypes.func.isRequired,
  onDecrease: PropTypes.func.isRequired,
  style: PropTypes.object,
};
