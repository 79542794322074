import classnames from 'classnames';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

export function DealerDropdown({ isOpen = true, toggle }) {
  return (
    <>
      <button type="button" onClick={toggle} className={styles.dropdown}>
        <h2 className={styles['dropdown-label']}>Locate a dealer</h2>

        <div
          className={classnames(styles['dropdown-trigger'], {
            [styles['dropdown-trigger-active']]: isOpen,
          })}
        >
          <span className={styles['dropdown-trigger-line']} />
          <span className={styles['dropdown-trigger-line']} />
          <span className={styles['dropdown-trigger-line']} />
        </div>
      </button>
    </>
  );
}

DealerDropdown.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
};
