import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { useRegionContext } from '../../context/Region';
import { NAV_ITEMS } from '../../constants/nav';

import { Dropdown } from '../Dropdown';
import { NavItems } from './NavItems';

import styles from './index.module.scss';

export const LocationsSidebar = forwardRef(({ isOpen, style }, ref) => {
  const { currentRegion } = useRegionContext();

  const isCountryDropdownOpen = (items) => {
    if (!currentRegion) return false;

    return items.some((item) => item.code === currentRegion.code);
  };

  return (
    <div ref={ref} style={style} className={styles.sidebar}>
      {isOpen &&
        NAV_ITEMS.map((navItem) => (
          <Dropdown key={navItem.title} label={navItem.title} isOpen={isCountryDropdownOpen(navItem.items)}>
            <NavItems items={navItem.items} />
          </Dropdown>
        ))}
    </div>
  );
});

LocationsSidebar.propTypes = {
  isOpen: PropTypes.bool,
  style: PropTypes.object,
};
