import axios from 'axios';
import { AppDependenciesContextProvider } from './context';
import { useToken } from './hooks/useToken';
import Login from './pages/Login';
import Map from './pages/Map';
import { Header } from './components/Header';
import { RegionContextProvider } from './context/Region';
import { MapTransformProvider } from './providers/MapTransformProvider';
import isElectron from "is-electron";
import './App.css'
import {useEffect, useState} from "react";
import classnames from "classnames";

function App() {

  const { token, setToken } = useToken();
  const [showFullscreenBtn, setShowFullScreenBtn] = useState(true);

  const exitHandler = () => {
    setShowFullScreenBtn(!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement);
  }

  useEffect(() => {
    if (document.addEventListener) {
      document.addEventListener('fullscreenchange', exitHandler, false);
      document.addEventListener('mozfullscreenchange', exitHandler, false);
      document.addEventListener('MSFullscreenChange', exitHandler, false);
      document.addEventListener('webkitfullscreenchange', exitHandler, false);
    }

    if (isElectron()) {
      window.api.enterFullScreen(data => {
        setShowFullScreenBtn(!data);
      });
    }

  }, [])

  const toggleFullscreen = () => {
    if (isElectron()) {
      window.api.toggleFullscreen().then();
      return;
    }
    webToggleFullscreen();
  }

  const webToggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().then();
    } else if (document.exitFullscreen) {
      document.exitFullscreen().then();
    }
  }

  const [downloading, setDownloading] = useState(null);
  useEffect(() => {
    if (isElectron()) {
      window.api.downloadInProgress(data => {
        setDownloading(data)
      });
    }
  }, []);

  if (!token) {
    return (
        <>
          <Login setToken={setToken} />
          <div onClick={toggleFullscreen} className={'toggleFullscreen'}></div>
        </>
    )
  }

  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token; // use token for all api requests

  return (
      <>
        <AppDependenciesContextProvider>
          <RegionContextProvider>
            <MapTransformProvider
              renderHeader={() => <Header />}
              renderMap={() => <Map />}
              renderControls={() => <Map.Controls />}
            />
          </RegionContextProvider>
        </AppDependenciesContextProvider>

        <div onClick={toggleFullscreen} className={classnames('toggleFullscreen', {'hidden': !showFullscreenBtn})}></div>

        {downloading && <div className={classnames('overlay')}>
          <div className={classnames('circle')}></div>
        </div>}

      </>
  );
}

export default App;
