import PropTypes from 'prop-types';

import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrow-left.svg';

import styles from './index.module.scss';

export function RestartButton({ onReset }) {
  return (
    <button type="button" className={styles.button} onClick={onReset}>
      <ArrowLeftIcon />
      <span>Restart</span>
    </button>
  );
}

RestartButton.propTypes = {
  onReset: PropTypes.func.isRequired,
};
